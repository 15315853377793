// Load our stylesheet:
import './style.css';



class ScrollDownMessage {
    constructor(id) {
        this.id = id;

        this.toggle.bind(this);
        this.hide.bind(this);
        this.show.bind(this);
    }

    toggle() {
        let el = document.querySelector(this.id);
        el.classList.toggle('hidden');
        el.classList.add('animate-bounce');
    }

    hide() {
        let el = document.querySelector(this.id);
        
        el.classList.remove('animate-bounce');
        el.style.display = 'none';
    }

    show() {
        let el = document.querySelector(this.id);
        el.style.display = 'block';
        el.classList.add('animate-bounce');
    }
};

var isInViewport = (elem) => {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};



var scrollMsg = new ScrollDownMessage('#message-scroll-down');
var scrolledPastHero = false;

window.addEventListener('load', (e) => {
    
    var t = setTimeout(() => {
        console.log('Showing scroll down message');

        scrollMsg.show();

        clearTimeout(t);
    }, 1000);

    
    document.getElementById('section-what-we-do_button-get-in-touch').addEventListener('click', (e) => {
        e.preventDefault();

        // Jump to element on page:
        // https://stackoverflow.com/questions/13735912/anchor-jumping-by-using-javascript
        var top = document.getElementById('section-contact-us').offsetTop -
                        document.getElementById('navbar').offsetHeight; //Getting Y of target element, adjusted for navbar
        window.scrollTo(0, top);                        //Go there directly or some transition​
    })
});

window.addEventListener('scroll', () => {
    //console.log(`Window scroll x: ${window.scrollX}, window scroll y:  ${window.scrollX}`);

    var sectionWhatWeDoHeading = document.querySelector('#section-what-we-do_heading');

    if(isInViewport(sectionWhatWeDoHeading) && !scrolledPastHero) {
        // Hide scroll message
        scrollMsg.hide();
        scrolledPastHero = true;
    }

});
